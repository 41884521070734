<template>
  <div class="statistics-drug-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="105" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="类型：">
              <Select clearable v-model="searchForm.need_type" placeholder="请选择">
                <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="药名：">
              <Input v-model="searchForm.drug_id" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="15">
            <Form-item label="服用依从性：">
              <Select clearable placeholder="请选择" v-model="searchForm.complianceId">
                <Option v-for="item in complianceData" :value="item.id" :key="item">{{ item.name }}</Option>
              </Select>
            </Form-item>
            <!-- <Form-item label="已服数量：">
              <Row>
                <Col span="10">
                  <Form-item prop="have_use_start">
                    <Input v-model="searchForm.have_use_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="have_use_end">
                    <Input v-model="searchForm.have_use_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">盒</Col>
              </Row>
            </Form-item> -->
          </Col>
          <!-- <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="未服数量：">
              <Row>
                <Col span="10">
                  <Form-item prop="no_use_start">
                    <Input v-model="searchForm.no_use_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="no_use_end">
                    <Input v-model="searchForm.no_use_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">盒</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col> -->
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="采购地点：">
              <Input v-model="searchForm.place" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="开始服用时间:">
              <Date-picker @on-change="changeTime" type="date" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="drugData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
import MemberService from '@/services/memberService';
export default {
	data() {
		// const haveUseSmall = (rule, value, callback) => {
		//   if (value === '') {
		//     if (this.searchForm.have_use_end !== '') {
		//       this.$refs.formValidate.validateField('have_use_end')
		//     }
		//     callback()
		//   } else {
		//     if (Number.isNaN(Number(value))) {
		//       callback(new Error('请输入数字值'))
		//     } else {
		//       if (this.searchForm.have_use_end !== '') {
		//         this.$refs.formValidate.validateField('have_use_end')
		//       }
		//       callback()
		//     }
		//   }
		// }
		// const haveUseLarge = (rule, value, callback) => {
		//   if (value === '') {
		//     callback()
		//   } else {
		//     if (Number.isNaN(Number(value))) {
		//       callback(new Error('请输入数字值'))
		//     } else {
		//       if (this.searchForm.have_use_start !== '' && Number(value) < Number(this.searchForm.have_use_start)) {
		//         callback(new Error('请输入更大数值'))
		//       }
		//       callback()
		//     }
		//   }
		// }
		// const noUseSmall = (rule, value, callback) => {
		//   if (value === '') {
		//     if (this.searchForm.no_use_end !== '') {
		//       this.$refs.formValidate.validateField('no_use_end')
		//     }
		//     callback()
		//   } else {
		//     if (Number.isNaN(Number(value))) {
		//       callback(new Error('请输入数字值'))
		//     } else {
		//       if (this.searchForm.no_use_end !== '') {
		//         this.$refs.formValidate.validateField('no_use_end')
		//       }
		//       callback()
		//     }
		//   }
		// }
		// const noUseLarge = (rule, value, callback) => {
		//   if (value === '') {
		//     callback()
		//   } else {
		//     if (Number.isNaN(Number(value))) {
		//       callback(new Error('请输入数字值'))
		//     } else {
		//       if (this.searchForm.no_use_start !== '' && Number(value) < Number(this.searchForm.no_use_start)) {
		//         callback(new Error('请输入更大数值'))
		//       }
		//       callback()
		//     }
		//   }
		// }
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				need_type: '',
				drug_id: '',
				// have_use_start: '',
				// have_use_end: '',
				// no_use_start: '',
				// no_use_end: '',
				place: '',
				time: '',
				age_start: '',
				age_end: '',
				complianceId: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				need_type: '',
				drug_id: '',
				// have_use_start: '',
				// have_use_end: '',
				// no_use_start: '',
				// no_use_end: '',
				place: '',
				time: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			typeList: [
				{
					id: 1,
					name: '成药',
				},
				{
					id: 2,
					name: '要素',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			complianceData: [],
			columns: [
				// { type: 'selection', width: 60, align: 'center' },
				{ title: '所属分院', key: 'branch_name', align: 'center' },
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.drugData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '类型',
					key: 'type_name',
					align: 'center',
				},
				{
					title: '名称',
					key: 'drug_name',
					align: 'center',
				},
				{ title: '开始服用时间', key: 'start_time', align: 'center' },
				{ title: '服用依从性', key: 'compliance', align: 'center' },
				// { title: '已服数量', key: 'have_use', align: 'center' },
				// { title: '未服数量', key: 'no_use', align: 'center' },
				{ title: '采购地点', key: 'place', align: 'center' },
			],
			drugData: [],
			pageTotal: 0,
			ruleValidate: {
				// have_use_start: [
				//   { validator: haveUseSmall, trigger: 'blur' }
				// ],
				// have_use_end: [
				//   { validator: haveUseLarge, trigger: 'blur' }
				// ],
				// no_use_start: [
				//   { validator: noUseSmall, trigger: 'blur' }
				// ],
				// no_use_end: [
				//   { validator: noUseLarge, trigger: 'blur' }
				// ],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getDrugData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
		this.getComplianceList();
	},
	methods: {
		// 获取服药服从性列表
		getComplianceList() {
			MemberService.complianceList().then((data) => {
				this.complianceData = data;
			});
		},
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getDrugData() {
			statisticsService.getDrugData(this.searchForm).then((data) => {
				this.drugData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getDrugData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.need_type = this.searchForm.need_type;
					this.exportForm.drug_id = this.searchForm.drug_id;
					// this.exportForm.have_use_start = this.searchForm.have_use_start
					// this.exportForm.have_use_end = this.searchForm.have_use_end
					// this.exportForm.no_use_start = this.searchForm.no_use_start
					// this.exportForm.no_use_end = this.searchForm.no_use_end
					this.exportForm.place = this.searchForm.place;
					this.exportForm.time = this.searchForm.time;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
					this.exportForm.complianceId = this.searchForm.complianceId;
				}
			});
		},
		changeTime(date) {
			this.searchForm.time = date;
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getDrugData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/drug/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&need_type=' +
				this.exportForm.need_type +
				'&drug_id=' +
				this.exportForm.drug_id +
				// '&have_use_start=' + this.exportForm.have_use_start +
				// '&have_use_end=' + this.exportForm.have_use_end +
				// '&no_use_start=' + this.exportForm.no_use_start +
				// '&no_use_end=' + this.exportForm.no_use_end +
				'&complianceId=' +
				this.exportForm.complianceId +
				'&place=' +
				this.exportForm.place +
				'&time=' +
				this.exportForm.time +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-drug-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
